import styled from "styled-components"

import { TBillingHistoryResponse } from "src/data/billing/types/billingTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { colorsV2 } from "src/ui/colors"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate, formatDateAsTime } from "src/utils/l10n"

export function BillingHistoryMobileTable({
  events,
}: {
  events: TBillingHistoryResponse["events"]
}) {
  const user = useGetUser()

  const { t } = useTranslate()

  return (
    <Wrapper>
      {events.map((event) => (
        <ExpandableSection
          key={event.event_id}
          title={
            <div>
              <MText
                variant="subtitle"
                color={
                  event.event_type === "home_deleted" ? "emergency" : "primary"
                }
              >
                {event.user_representation.description}
              </MText>
              <DateTimeWrapper>
                <MText variant="bodyS" color="secondary">
                  {formatDate({ date: event.created_at, excludeTime: true })}
                </MText>
                <MText color="secondary">&bull;</MText>
                <MText variant="bodyS" color="secondary">
                  {formatDateAsTime({
                    date: event.created_at,
                    clockType: user.clock_type,
                    excludeSeconds: true,
                  })}
                </MText>
              </DateTimeWrapper>
            </div>
          }
        >
          <DetailsWrapper>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.home_name)}
              </MText>
              <MText variant="bodyS">
                {event.user_representation.home_name && event.home_id ? (
                  <InternalLink to={Routes.Home.location(event.home_id)}>
                    {event.user_representation.home_name}
                  </InternalLink>
                ) : (
                  "-"
                )}
              </MText>
            </DetailsGrid>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.billing_history_payment)}
              </MText>
              <MText variant="bodyS">
                {event.user_representation.payment || "-"}
              </MText>
            </DetailsGrid>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.billing_history_note)}
              </MText>
              <MText variant="bodyS">{event.user_representation.note}</MText>
            </DetailsGrid>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.organization)}
              </MText>
              <MText variant="bodyS">
                {event.user_representation.organization_name}
              </MText>
            </DetailsGrid>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.billing_history_homegroup)}
              </MText>
              <MText variant="bodyS">
                {event.user_representation.home_group && event.home_group_id ? (
                  <InternalLink
                    to={Routes.HomeGroupDetails.location(event.home_group_id)}
                  >
                    {event.user_representation.home_group}
                  </InternalLink>
                ) : (
                  "-"
                )}
              </MText>
            </DetailsGrid>
            <DetailsGrid>
              <MText variant="subtitleS" color="tertiary">
                {t(langKeys.billing_history_modified_by)}
              </MText>
              <MText variant="bodyS">
                {event.user_representation.modified_by}
              </MText>
            </DetailsGrid>
          </DetailsWrapper>
        </ExpandableSection>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;

  & > * {
    padding-bottom: ${spacing.M};
    margin-bottom: ${spacing.M};
    border-bottom: 1px solid ${colorsV2.divider};
    // There is a default border-radius on ExpandableSection
    border-radius: 0;
  }
`

const DetailsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-top: ${spacing.M};
`

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
