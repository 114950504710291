import styled from "styled-components"

import { BillingAddress } from "src/components/Account/BillingPortal/BillingAddress/BillingAddress"
import { BillingFeedback } from "src/components/Account/BillingPortal/BillingFeedback"
import { BillingHistory } from "src/components/Account/BillingPortal/BillingHistory/BillingHistory"
import { PaymentMethod } from "src/components/Account/BillingPortal/PaymentMethod/PaymentMethod"
import { YourPlanLoading } from "src/components/Account/BillingPortal/YourPlan/YourPlanLoading"
import { useFlagProvider } from "src/components/Flags/useFlagProvider"
import {
  HREF_MINUT_SUBSCRIPTION_TERMS,
  HREF_MINUT_TERMS_OF_SERVICE,
} from "src/constants/hrefs"
import { useAppData } from "src/context/useAppData"
import { useFetchOrCreateCustomer } from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { divider } from "src/ui/colors"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { Payments } from "./Payments/Payments"
import { YourPlan } from "./YourPlan/YourPlan"

export function BillingPortal() {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const { currentSubscription } = useAppData()
  const fetchOrCreateCustomer = useFetchOrCreateCustomer({ user })

  const { flags } = useFlagProvider()

  if (fetchOrCreateCustomer.isError) {
    return <MBanner type="error">{t(langKeys.failed_contact_support)}</MBanner>
  }

  const customer = fetchOrCreateCustomer.data
  const isValidLocation = customer?.is_location_valid ?? true

  if (fetchOrCreateCustomer.isLoading || !customer) {
    return <YourPlanLoading />
  }

  return (
    <>
      <DocumentHead title={t(langKeys.settings_billing)} />

      <FlexBox>
        <PlanBox>
          <YourPlan
            balances={customer.balances}
            taxability={customer.taxability}
            hasCSM={!!customer.cf_csm}
          />
        </PlanBox>

        <BillingBox>
          <div>
            {!isValidLocation && (
              <StyledMAlert type="error">
                {t(langKeys.billing_location_verification_error)}
              </StyledMAlert>
            )}

            <MText variant="heading3" marginBottom={spacing.S}>
              {t(langKeys.payment_method)}
            </MText>

            <PaymentMethod customer={customer} userId={user.user_id} />
          </div>

          <div>
            <MText variant="heading3">{t(langKeys.billing_address)}</MText>

            <MText variant="bodyS" color="secondary" marginBottom={spacing.M}>
              {t(langKeys.billing_address_payment_method_match)}
            </MText>

            <BillingAddress />
          </div>
        </BillingBox>
      </FlexBox>

      <TableBox>
        <MText variant="heading3" marginBottom={spacing.M}>
          {t(langKeys.payments)}
        </MText>
        <Payments />
      </TableBox>

      <TableBox>
        {flags.showInvoiceBreakdown &&
          currentSubscription?.billing_model === "plan_unit_per_home" && (
            <>
              <MText variant="heading3" marginBottom={spacing.L}>
                {t(langKeys.billing_history_title)}
              </MText>
              <BillingHistory />
              <TableBox>
                <BillingFeedback />
              </TableBox>
            </>
          )}
      </TableBox>

      <Footer>
        <ExternalLink
          href={HREF_MINUT_TERMS_OF_SERVICE}
          color="tertiary"
          underline="onHover"
        >
          {t(langKeys.terms_of_service)}
        </ExternalLink>

        <ExternalLink
          href={HREF_MINUT_SUBSCRIPTION_TERMS}
          color="tertiary"
          underline="onHover"
        >
          {t(langKeys.subscription_terms)}
        </ExternalLink>
      </Footer>
    </>
  )
}

const minWidth = "1200px"

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${minWidth}) {
    flex-direction: row;
  }
`

const PlanBox = styled.div`
  flex: 1;
  margin-bottom: ${spacing.XL};
  @media (min-width: ${minWidth}) {
    padding-right: ${spacing.XL2};
    border-right: 1px solid ${divider};
  }
`

const BillingBox = styled.div`
  display: grid;
  gap: ${spacing.XL};

  @media (min-width: ${minWidth}) {
    max-width: 450px;
    padding-left: ${spacing.XL};
  }
`

const TableBox = styled.div`
  margin-top: ${spacing.XL3};
`

const StyledMAlert = styled(MBanner)`
  margin-bottom: ${spacing.S};
`

const Footer = styled.div`
  display: flex;
  gap: ${spacing.M};
  margin-top: ${spacing.L};
`
