import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { colorsV2 } from "src/ui/colors"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BillingFeedback() {
  const { t, langKeys } = useTranslate()

  return (
    <MCard boxShadow={false} backgroundColor={colorsV2.primaryLight}>
      <Box>
        <div>
          <MText variant="heading3">
            {t(langKeys.billing_page_feedback_title)}
          </MText>
          <MText>{t(langKeys.billing_page_feedback_body)}</MText>
        </div>

        <MButton
          variant="subtle"
          onClick={() => {
            window.open("https://forms.gle/tuAKdhEa7nYY2vK7A", "_blank")
          }}
        >
          {t(langKeys.feedback_action)}
        </MButton>
      </Box>
    </MCard>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  grid-template-columns: 1fr auto;
  align-items: center;
`
