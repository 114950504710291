import { API_DEFAULT } from "src/constants/minutApi"
import {
  EditType,
  IDropdown,
  IPhoneInput,
  ITextInput,
  IToggle,
} from "src/data/editField/editFieldTypes"
import { TUser } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { SUPPORTED_LANGUAGES } from "src/i18n/supportedLanguages.mjs"
import { formatPhoneNumber } from "src/ui/PhoneNumberInput/phoneNumberUtil"

export interface IProfileSetting extends TUser {}

export type IProfileSettingPayload = Partial<IProfileSetting>

const lngToNameMap: { [key: string]: string } = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  it: "Italiano",
  nl: "Nederlands",
  pt: "Português",
}

const languageOptions = SUPPORTED_LANGUAGES.map((l) => {
  return {
    name: lngToNameMap[l],
    value: l.replace("-", "_"),
  }
}).filter((o) => Boolean(o.name))

export const Language: IDropdown<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.settings_language,
  info: "",
  type: EditType.DROPDOWN,
  options: [{ name: "Auto detect", value: "" }, ...languageOptions],
  id: "profile.preferred_language",
  defaultValue: "",
  storedValue: (settings: IProfileSetting): string | "" => {
    return settings.preferred_language || ""
  },
  payload: (preferred_language: string) => ({ preferred_language }),
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.user_id}`,
}

export const ClockType: IDropdown<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.settings_time_format,
  info: "",
  type: EditType.DROPDOWN,
  options: [
    {
      name: langKeys.settings_auto_detect,
      value: "",
      disabled: true,
      hidden: true,
    },
    { name: langKeys.settings_time_format_24_hour, value: "24" },
    { name: langKeys.settings_time_format_12_hour, value: "12" },
  ],
  id: "profile.clock_type",
  defaultValue: "",
  affectsHomes: true,
  storedValue: (settings: IProfileSetting): "12" | "24" | "" => {
    return settings?.clock_type || ""
  },
  payload: (clock_type: "12" | "24") => ({ clock_type }),
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.user_id}`,
  translateDisplayValue: true,
}

export const Temperature: IDropdown<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.temperature,
  info: "",
  type: EditType.DROPDOWN,
  options: [
    {
      name: langKeys.settings_auto_detect,
      value: "",
      disabled: true,
      hidden: true,
    },
    { name: langKeys.celsius_long, value: "C" },
    {
      name: langKeys.fahrenheit_long,
      value: "F",
    },
  ],
  id: "profile.temperature",
  defaultValue: "",
  storedValue: (settings: IProfileSetting) => {
    return settings?.temperature_unit || ""
  },
  payload: (temperature_unit: "C" | "F") => ({ temperature_unit }),
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.user_id}`,
  translateDisplayValue: true,
}

export const EmailNotifications: IToggle<
  IProfileSetting,
  IProfileSettingPayload
> = {
  title: langKeys.email_notifications,
  info: langKeys.email_notifications_info,
  type: EditType.TOGGLE,
  id: "profile.email_notification",
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  storedValue: (settings: IProfileSetting): boolean => {
    return !!settings?.email_notifications
  },
  payload: (email_notifications: boolean) => ({ email_notifications }),
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.user_id}`,
  inlineEdit: true,
}

export const UserName: ITextInput<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.name,
  type: EditType.INPUT_TEXT,
  defaultValue: "", // needs to be populated
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.fullname}`,
  maxLength: 100,
  info: "",
  storedValue: (settings: IProfileSetting) => {
    return settings.fullname
  },
  payload: (fullname: string) => {
    return { fullname }
  },
  onError: (err) => {
    switch (err) {
      case 400:
        return "Invalid format."
      case 409:
      default:
        return "Something went wrong."
    }
  },
}

export const UserPhone: IPhoneInput<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.phone,
  info: "",
  type: EditType.PHONE_NUMBER,
  endpointUrl: () => `${API_DEFAULT}/users/`,
  storedValue: (s) => formatPhoneNumber(s.phone_number ?? ""),
  payload: (phone_number: string) => {
    return { phone_number }
  },
  defaultValue: "",
}

export const UserEmail: ITextInput<IProfileSetting, IProfileSettingPayload> = {
  title: langKeys.email,
  type: EditType.INPUT_TEXT,
  defaultValue: "", // needs to be populated
  endpointUrl: (settings: IProfileSetting) =>
    `${API_DEFAULT}/users/${settings.user_id}`,
  maxLength: 150,
  info: "",
  storedValue: (settings: IProfileSetting) => {
    return settings.email
  },
  payload: (email: string) => {
    return { email }
  },
  onError: (err) => {
    switch (err) {
      case 400:
        return "Invalid email format."
      case 409:
        return "Email already exists."
      default:
        return null
    }
  },
  capitalize: false,
}
