import { FormEvent, useState } from "react"
import styled from "styled-components"

import { useMediaQuery } from "@material-ui/core"

import { IBillingAddress } from "src/components/Account/types"
import { usePostUpdateBillingInfo } from "src/data/billing/queries/billingQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

import { BillingFields } from "./BillingFields"
import { transformBillingAddress } from "./saveAddress"
import { useBillingFields } from "./useBillingFields"

export function EditBillingDialog({
  vatNumber,
  billingAddress,
  customerId,
  open = false,
  onClose,
}: {
  open: boolean
  customerId: string
  onClose: () => void
  vatNumber?: string
  billingAddress?: IBillingAddress
}) {
  const { t } = useTranslate()
  const [saving, setSaving] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const [error, setError] = useState<any>(null)
  const {
    billingAddress: _billingAddress,
    vatNumber: _vatNumber,
    setField,
    setVatNumber,
  } = useBillingFields(billingAddress, vatNumber)
  const postUpdateBillingInfo = usePostUpdateBillingInfo()

  const fullScreen = useMediaQuery(`(max-width: 800px)`)
  const dialogId = "edit-billing-address-dialog"
  const formId = "edit-billing-address-form"

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    setError(null)
    setSaving(true)

    try {
      if (!customerId) {
        throw Error(
          "No customer id present when trying to save billing address"
        )
      }

      await postUpdateBillingInfo.mutateAsync({
        customerId,
        body: {
          vat_number: _vatNumber,
          billing_address: transformBillingAddress(_billingAddress),
        },
      })

      onClose()
    } catch (err) {
      setError(err)
    }

    setSaving(false)
  }

  return (
    <MDialog
      title={t(langKeys.billing_address)}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby={dialogId}
      formId={formId}
      loading={saving}
      error={
        error &&
        ((error?.response?.status === 400 && error?.response?.data.error_msg) ||
          `${t(langKeys.failed_to_save)}. ${t(langKeys.failed_contact_support)}`)
      }
    >
      <Form id={formId} onSubmit={handleSubmit}>
        <BillingFields
          billingAddress={_billingAddress}
          vatNumber={_vatNumber}
          setField={setField}
          setVatNumber={setVatNumber}
        />
      </Form>
    </MDialog>
  )
}

const Form = styled.form`
  padding-top: ${spacing.M};
`
